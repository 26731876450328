<template>
    <main>
        <div id="banner">
            <header>
                <nav class="navbar navbar-expand-lg fixed-top">
                    <div class="container-fluid mx-3">
                        <a class="navbar-brand" href="#">
                            <!-- <h1 class="logo">Hecto</h1> -->
                            <img src="../assets/nav/HectoWhiteLogo.svg">
                        </a>
                        <button class="navbar-dark navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item">
                                    <a class="nav-link" href="#features">Features</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a class="nav-link" href="#pricing">Pricing</a>
                                </li> -->
                                <li class="nav-item">
                                    <router-link to="/contact-us" class="nav-link">Contact Us</router-link>
                                </li>
                                <li class="d-flex align-items-center mt-3">
                                    <span to="pre-register" class="demo-link" @click="goToRequestDemo()">REQUEST DEMO</span>
                                </li>
                                <li class="d-flex align-items-center mt-3 login-logo">
                                    <span class="ml-3 demo-link" @click="gotoLogin()">
                                        <img src="../assets/landing/SVG/enter.svg" alt="login" width="20px"> LOGIN
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <!-- <div class="back-img"></div> -->
            <!-- <img src="images/pexels-cleyder-duque-3821384.jpg" alt="banner"> -->
            <div class="content">
                <h4>Handle your online stores with ease</h4>
                <h1>Manage multiple stores in A Single Dashboard</h1>
                <router-link to="pre-register">
                    <div class="demo-btn">
                        <span class="request-demo">Request Demo</span>
                        <span><img src="../assets/landing/SVG/Group 4110.svg" alt=""></span>
                    </div>
                </router-link>
            </div>
        </div>

    <section id="marketplace">
        <div class="container-fluid mx-3">
            <h1>Integrate<br /> With Marketplaces & Platforms</h1>
            <div class="partners">
                <img src="../assets/landing/SVG/Amazon Color.svg" style="margin-top: 10px;" alt="Amazon">
                <img src="../assets/landing/SVG/flipkart Color.svg" alt="Flipkart">
                <img src="../assets/landing/SVG/Shiprocket Color.svg" alt="Shiprocket">
                <img src="../assets/landing/SVG/JioMart Color.svg" alt="JioMart">
            </div>
        </div>
    </section>

    <section id="grow">
        <h1>HOW DOES OUR PLATFORM HELP GROW YOUR BUSINESS??</h1>
        <div class="items">
            <div class="item">
                <div class="item-image">
                    <img src="../assets/landing/SVG/sales Line.svg" alt="Increase in sales and Revenue">
                    <img src="../assets/landing/SVG/Sales Color.svg" alt="Increase in sales and Revenue">
                </div>
                <h3>Increase in sales and Revenue</h3>
            </div>
            <div class="item">
                <div class="item-image">
                    <img src="../assets/landing/SVG/cashless-payment line.svg" alt="Effortless payment reconcilations">
                    <img src="../assets/landing/SVG/cashless-payment Color.svg" alt="Effortless payment reconcilations">
                </div>
                <h3>Effortless payment reconcilations</h3>
            </div>
            <div class="item">
                <div class="item-image">
                    <img src="../assets/landing/SVG/Sales Analysis Line.svg" alt="Easy sales analysis">
                    <img src="../assets/landing/SVG/Sales Analysis Color.svg" alt="Easy sales analysis">
                </div>
                <h3>Easy sales analysis</h3>
            </div>
            <div class="item">
                <div class="item-image">
                    <img src="../assets/landing/SVG/Lower Man Power Line.svg" alt="Lower man power cost">
                    <img src="../assets/landing/SVG/Lower Man Power Color.svg" alt="Lower man power cost">
                </div>
                <h3>Lower man power cost</h3>
            </div>
            <div class="item">
                <div class="item-image">
                    <img src="../assets/landing/SVG/inventory Line.svg" alt="centralised inventory sync">
                    <img src="../assets/landing/SVG/inventory Color.svg" alt="centralised inventory sync">
                </div>
                <h3>centralised inventory sync</h3>
            </div>
            <div class="item">
                <div class="item-image">
                    <img src="../assets/landing/SVG/Easy Return Line.svg" alt="Easy returns handling">
                    <img src="../assets/landing/SVG/Easy Return Color.svg" alt="Easy returns handling">
                </div>
                <h3>Easy returns handling</h3>
            </div>
        </div>
    </section>

    <section id="features">
        <div class="item">
            <img src="../assets/landing/pexels-pixabay-35550.jpg">
            <div class="content">
                <h3>Order Management</h3>
                <p>HectoCommerce is an aggregator of marketplaces like Amazon, Flipkart, Myntra, etc. Orders from
                    stores of various marketplaces are consolidated into single Dashboard and bulk scheduling the
                    orders for pickup is supported</p>
            </div>
        </div>
        <div class="item">
            <img src="../assets/landing/pexels-tiger-lily-4483610-com.jpg">
            <div class="content">
                <h3>Inventory Management</h3>
                <p>HectoCommerce Centralized inventory management system makes the inventory tracking across the
                    marketplaces easy, leading to uniform stock distribution based on the sales analysis. This increases
                    the cash flow and helps in easy stock rotation.</p>
            </div>
        </div>
        <div class="item">
            <img src="../assets/landing/pexels-cleyder-duque-3821385-com.jpg">
            <div class="content">
                <h3>Warehouse Management</h3>
                <p>Multiple warehouses can be mapped to each store. The inventory in multiple stores is immediately
                    updated once the stock in the warehouse is removed or added. Supports both marketplace warehouses
                    and merchant warehouses. This helps to fulfil orders from the nearest warehouse and utilize the
                    space more efficiently.</p>
            </div>
        </div>
        <div class="item">
            <img src="../assets/landing/pexels-photomix-company-106344-com.jpg">
            <div class="content">
                <h3>Sales Analytical Dashboard</h3>
                <p>HectoCommerce sales dashboard displays the key metrics for all the marketplaces in a single page.
                    Enables comparing the sales month wise, week wise and for any period to get clarity on the business
                    decisions. HectoCommerce brings in the sales comparison location wise as well which helps to plan
                    the inventory distribution across different cities.</p>
            </div>
        </div>
        <div class="item">
            <img src="../assets/landing/pexels-pixabay-50987-com.jpg">
            <div class="content">
                <h3>Payment Reconcilation</h3>
                <p>One of the major challenge in ecommerce is the reconciliation of the payments done by the
                    marketplaces. HectoCommerce platform gives order wise and SKU wise remittance details in a single
                    click. Finding out the profitability of the products is just a click away.</p>
            </div>
        </div>
    </section>

    <!-- <section id="pricing">
        <div class="container-fluid mx-3">
            <h1>PRICING</h1>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                et
                dolore magna aliquyam erat, sed diam voluptua.</p>
            <div class="row">
                <div class="col-lg-4">
                    <div class="item">
                        <div class="header">
                            <h3>Basic</h3>
                            <h2><span>$</span>30</h2>
                        </div>
                        <div class="body">
                            <ul>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                            </ul>
                        </div>
                        <div class="footer">
                            <a href="#" class="get-started-btn">Get Started</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="item">
                        <div class="header">
                            <h3>Startup</h3>
                            <h2><span>$</span>130</h2>
                        </div>
                        <div class="body">
                            <ul>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                            </ul>
                        </div>
                        <div class="footer">
                            <a href="#" class="get-started-btn">Get Started</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="item">
                        <div class="header">
                            <h3>Basic</h3>
                            <h2><span>$</span>30</h2>
                        </div>
                        <div class="body">
                            <ul>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                                <li>Lorem ipsum dolor sit amet</li>
                            </ul>
                        </div>
                        <div class="footer">
                            <a href="#" class="get-started-btn">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <div class="scene scene--card">
            <div class="card">
                <div class="card__face card__face--front">front</div>
                <div class="card__face card__face--back">back</div>
            </div>
        </div> 
    </section> -->


    <footer>
        <div class="container-fluid px-5">
            <div class="footer-items">
                <a class="navbar-brand" href="#">
                    <img src="../assets/nav/HectoWhiteLogo.svg">
                </a>
                <div class="footer-nav">
                    <a href="#">Home</a>
                    <a href="#features">Features</a>
                    <!-- <a href="#pricing">Pricing</a> -->
                    <router-link to="/contact-us">Contact Us</router-link>
                </div>
                <div class="social-icons">
                    <a href="https://www.facebook.com/HectoCommerce-102415571791861" target="_blank"><span><img src="../assets/landing/SVG/facebook-fill.svg" alt="facebook"></span></a>
                    <a href="https://www.linkedin.com/showcase/hectocommerce" target="_blank"><span><img src="../assets/landing/SVG/linkedin-fill.svg" alt="linkedin"></span></a>
                    <a><span><img src="../assets/landing/SVG/instagram-line.svg" alt="instagram"></span></a>
                </div>
            </div>
            <div class="copyright">
                <h3>Copyright © 2021 HectoCommerce, A DIATOZ Product, All rights reserved</h3>
            </div>
        </div>
    </footer>
    </main>
</template>

<script>

export default{
    methods:{
        gotoLogin(){
            this.$router.push("/login");
        },
        goToRequestDemo(){
            this.$router.push("/pre-register");
        }
    }
}
</script>

<style>
@font-face {
    font-family: AvenirNext;
    src: url('../assets/fonts/AvenirNextLTPro-Bold.otf');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

header .navbar {
    background: rgba(2, 40, 56, .35) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #0000000A;
}

header .navbar .navbar-brand .logo {
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 0;
}

header .navbar ul li a {
    font: normal normal bold 14px/53px Quasimoda;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    text-transform: uppercase;
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important;
}

header .navbar ul li a span {
    display: none;
}

header .navbar ul .login-logo a {
    position: relative;
}

header .navbar ul .login-logo a::after {
    content: 'Login';
    position: absolute;
    top: 10px;
    left: 70px;
    display: none;
    width: 30px;
    height: 30px;
    color: #FFFFFF;
    text-transform: uppercase;
    font: normal normal bold 14px/53px Quasimoda;
}

header .navbar ul .login-logo a:hover {
    display: block;
}

header .navbar ul li .login-icon a img {
    transition: all 3s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    /* position:absolute; */
}

header .navbar ul .login-icon a span {
    margin-left: 5px;
    transition: all 3s ease-in-out;
}

header .navbar ul .login-icon a:hover > span {
    display: inline;
}

header .navbar ul .login-icon img:hover {
    width: 30px;
    /* transform: translate(-3em,0);
  -webkit-transform: translate(-3em,0);
  -moz-transform: translate(-3em,0);
  -o-transform: translate(-3em,0);
  -ms-transform: translate(-3em,0); */
}

header .navbar ul .login-icon:hover > span {
    transform: translate(-3em,0);
}

header .navbar ul li:last-child a {
    padding-right: 0.5rem !important;
}

#banner {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background: rgba(2, 40, 56, .4) 0% 0% no-repeat padding-box;
}

#banner::before {
    content: "";
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: url('../assets/landing/pexels-cleyder-duque-3821384-com.jpg');
    background-size: cover;
    /* background: #022838 0% 0% no-repeat padding-box; */
}

#banner .content {
    text-transform: uppercase;
    text-align: center;
    color: #FFFFFF;
    max-width: 700px;
}
    
#banner .content h4 {
    font: normal normal normal 24px/33px Quasimoda;
    color: #EFEFEF;
}

#banner .content h1 {
    font: normal normal bold 50px/65px AvenirNext;
    margin-bottom: 20px;
    color: #EFEFEF;
}

#banner .content a {
    text-decoration: none;
}

#banner .demo-btn {
    background: #DB393A 0% 0% no-repeat padding-box;
    border-radius: 5px;
    display: inline;
    padding: 15px;
    color: #FFFFFF;
    position: relative;
    align-items: center;
    transition-duration: .2s;
    /* width: 5rem; */
}
#banner .demo-btn:hover{
    opacity: 0.8;
    transition: 0.1s linear;
}
/* #banner .demo-btn .request-demo{
    display: none;
} */
#banner .demo-btn span {
    margin-right: 5px;
}
/* #banner .demo-btn:hover{
    border-radius: 5px;
    animation-name: show-name;
    animation-duration: .1s;
} */
/* #banner .demo-btn:hover .request-demo{
    display: contents;
} */
/* @keyframes show-name {
    0% {
        border-radius: 30px;
        width: 3rem;
    }
    50%{
        border-radius: 20px;
        width: 10rem;
    }
    100%{
        border-radius: 10px;
        width: 10rem;
    }
} */
/* #banner .demo-btn:hover span {
    margin-left: 10px;
} */
#marketplace {
    margin: 3rem;
    text-align: center;
}

#marketplace h1 {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
}

#marketplace h1 {
    font: normal normal 600 24px/30px Quasimoda;
    letter-spacing: 0px;
    color: #8A91A2;
    text-transform: uppercase;
    margin-bottom: 3rem;
}

#marketplace .partners {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

#marketplace .partners img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

#marketplace .partners img:hover {
    -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
    filter: grayscale(0);
    transition: ease .5s;
}

#grow {
    background: #022838 0% 0% no-repeat padding-box;
    padding: 80px;
    color: #FFFFFF;
    text-align: center;
}

#grow h1 {
    font: normal normal 600 24px/33px Quasimoda;
    color: #E7E7E7;
    text-transform: uppercase;
    margin-bottom: 3rem;
}

#grow .items {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
}

#grow .items .item {
    max-width: 200px;
    margin: 5px;
}

#grow .items .item-image {
    display: inline-flex;
    transition: .5s;
}

#grow .items .item-image img:nth-child(1) {
    display: block;
    transition: .5s;
}

#grow .items .item-image img:nth-child(2) {
    display: none;
    transition: .5s;
}

#grow .items .item-image:hover {
    transform: rotate(45deg);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #FFFFFF1A;
    border-radius: 10px;
}

#grow .items .item-image:hover > img:nth-child(1) {
    display: none;
}

#grow .items .item-image:hover > img:nth-child(2) {
    display: block;
}

#grow .items .item-image img {
    width: 75px;
    height: 75px;
    padding: 15px;
}

#grow .items .item .item-image img:hover {
    transform: rotate(-45deg);
}

#grow .items .item h3 {
    font: normal normal bold 17px/24px Quasimoda;
    color: #E7E7E7;
    text-transform: uppercase;
    margin-top: 20px;
}

#features {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    position: relative;
}

#features .item {
    flex: 1;
    overflow: hidden;
    transition: 1s;
    line-height: 0;
    position: relative;
    top: 0;
    left: 0;
    background: #393939 0% 0% no-repeat padding-box;
}

#features .item img {
    width: 200%;
    height: 100%;
    object-fit: cover; 
    object-position: right bottom;
    opacity: 0.4;
    transition: 1s;
}

#features .item .content {
    position: absolute;
    top: 0;
    left: 0;
    color: #FFFFFF;
    padding: 50vh 15%;
    transition: 1s;
}

#features .item .content h3 {
    text-align: center;
    font: normal normal 600 18px/22px Quasimoda;
    letter-spacing: 0px;
    text-transform: uppercase;
}

#features .item .content p {
    font: normal normal normal 18px/30px Avenir Next;
    letter-spacing: 0px;
    display: none;
    margin-top: 2rem;
    width: 400px;
}

#features .item:hover { 
    flex: 1 1 30%; 
    background: #FF4040 0% 0% no-repeat padding-box;
}

#features .item:hover > img {
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

#features .item:hover > .content {
    padding: 40vh 15%;
}

#features .item:hover > .content h3 {
    text-align: left;
}

#features .item:hover > .content p {
    display: block;
}

#pricing {
    margin: 80px;
    text-align: center;
    color: #8A91A2;
}

#pricing h1 {
    font: normal normal 600 28px/33px Quasimoda;
    text-transform: uppercase;
}

#pricing p {
    font: normal normal normal 18px/30px Avenir Next;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

#pricing .item {
    box-shadow: 0px 3px 30px #0000000D;
    border-radius: 20px;
    padding-bottom: 40px;
    background-size: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
}

#pricing .item:hover {
    transform: rotateY(180deg);
}

#pricing .col-lg-4:nth-child(1) .item {
    background: transparent url('../assets/landing/SVG/Group\ 4165.svg') 0% 0% no-repeat padding-box;
    background-size: 100%;
}

#pricing .col-lg-4:nth-child(2) .item {
    background: transparent url('../assets/landing/SVG/Group\ 4166.svg') 0% 0% no-repeat padding-box;
    background-size: 100%;
}

#pricing .col-lg-4:nth-child(3) .item {
    background: transparent url('../assets/landing/SVG/Group\ 4167.svg') 0% 0% no-repeat padding-box;
    background-size: 100%;
}

#pricing .item .header {
    color: #FFFFFF;
    padding: 65px;
    text-transform: uppercase;
    min-height: 250px;
}

#pricing .item .header h3 {
    font: normal normal 600 24px/29px Quasimoda;
}

#pricing .item .header h2 span {
    font: normal normal 600 34px/41px Quasimoda;
    margin-right: 5px;
}

#pricing .item .header h2 {
    font: normal normal 600 50px/70px Quasimoda;
    display: flex;
    justify-content: center;
    align-items: center;
}

#pricing .item .body {
    padding: 30px 40px;
}

#pricing .item .body ul li {
    list-style: none;
    font: normal normal normal 18px/24px Avenir Next;
    padding: 1rem 0;
    border-bottom: 0.5px solid rgba(112, 112, 112, .2);
}

#pricing .item .footer {
    border-radius: 24px;
    display: inline;
    padding: 10px 30px;
}

#pricing .col-lg-4:nth-child(1) .footer {
    background: #A08CC2 0% 0% no-repeat padding-box;
}

#pricing .col-lg-4:nth-child(2) .footer {
    background: #4DBC9F 0% 0% no-repeat padding-box;
}

#pricing .col-lg-4:nth-child(3) .footer {
    background: #32A4DD 0% 0% no-repeat padding-box;
}

#pricing .item .footer a {
    text-decoration: none;
    color: #FFFFFF;
}

/* .scene {
    width: 200px;
    height: 260px;
    margin: 40px 0;
    perspective: 600px;
  }
  
  .card {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
  }
  
  .card:hover {
    transform: rotateY(180deg);
  }
  
  .card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 260px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .card__face--front {
    background: red;
  }
  
  .card__face--back {
    background: blue;
    transform: rotateY(180deg);
  } */

footer {
    background: #022838 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 60px 0 50px;
}

footer .footer-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

footer .footer-items h1 {
    font-size: 26px;
    margin-bottom: 0;
    margin-right: 4rem;
}

footer .footer-items .footer-nav a {
    font: normal normal bold 14px/53px Quasimoda;
    letter-spacing: 0px;
    color: #8A91A2;
    text-decoration: none;
    margin: 0 8px;
}

footer .footer-items .social-icons a {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 10px 8px;
    border-radius: 50%;
    margin: 0 5px;
}

footer .copyright h3 {
    margin-top: 30px;
    margin-bottom: 0;
    font: normal normal normal 16px/19px Quasimoda;
    text-align: center;
}
.demo-link{
    background:#DB393A;
    padding: 8px 10px;
    color: #fff;
    opacity: 0.8;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}
@media screen and (max-width: 991.99px){
    .demo-link:nth-child(1){
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    header .navbar ul .login-logo img {
        display: none;
    }
    header .navbar ul .login-logo a::after {
        position: relative;
        display: block;
        top: 0;
        left: 0;
        margin-bottom: 10px;
    }
    #banner .content h4 {
        font: normal normal normal 14px/15px Quasimoda;
    }

    #banner .content h1 {
        font: normal normal bold 25px/33px AvenirNext;
    }

    #banner .demo-btn {
        padding: 12px;
        font-size: 15px;
    }

    #marketplace {
        margin: 2rem .1rem;
    }

    #marketplace h1 {
        font: normal normal 600 18px/22px Quasimoda;
        width: auto;
        margin-bottom: 2rem;
    }

    #marketplace .partners img {
        margin: 20px 30px;
        filter: grayscale(0);
    }

    #grow {
        padding: 35px;
    }

    #grow h1 {
        margin-bottom: 2rem;
        font: normal normal 600 18px/22px Quasimoda;
    }

    #grow .items .item {
        margin: 15px;
    }

    #grow .items .item-image {
        transform: rotate(45deg);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 15px #FFFFFF1A;
        border-radius: 10px;
    }

    #grow .items .item-image img {
        transform: rotate(-45deg);
    }

    #grow .items .item-image img:nth-child(1) {
        display: none;
    }
    
    #grow .items .item-image img:nth-child(2) {
        display: block;
    }

    #grow .items .item h3 {
        font: normal normal bold 14px/25px Quasimoda;
    }

    #features {
        display: block;
        height: auto;
    }

    #features .item .content {
        padding: 30% 10%;
    }

    #features .item .content h3 {
        font: normal normal 600 16px/19px Quasimoda;
        text-align: left;
    }

    #features .item .content p {
        display: block;
        width: auto;
        font: normal normal normal 16px/30px Avenir Next;
    }

    #features .item:hover {
        background: #393939 0% 0% no-repeat padding-box;
    }
    
    #features .item:hover > img {
        width: 200%;
        height: 100%;
        opacity: 0.4;
    }
    
    #features .item:hover > .content {
        padding: 30% 10%;
    }
    
    #features .item:hover > .content h3 {
        text-align: left;
    }
    
    #features .item:hover > .content p {
        display: block;
    }

    #pricing {
        margin: 50px 10px;
    }

    #pricing h1 {
        font: normal normal 600 18px/22px Quasimoda;
    }

    #pricing p {
        width: auto;
        font: normal normal normal 14px/30px Avenir Next;
    }

    #pricing .item {
        margin-bottom: 2rem;
    }

    #pricing .item .header {
        padding: 35px 0;
        min-height: 170px;
    }

    #pricing .item .header h3 {
        font: normal normal 600 21px/25px Quasimoda;
    }

    #pricing .item .header h2 {
        font: normal normal 600 60px/72px Quasimoda;
    }

    #pricing .item .header h2 span {
        font: normal normal 600 31px/37px Quasimoda;
    }

    #pricing .item .body {
        padding: 50px 30px 40px;
    }

    #pricing .item .body ul li {
        font: normal normal normal 14px/24px Avenir Next;
        padding: 1.2rem 0;
    }

    footer {
        padding: 30px 0;
    }
    
    footer .footer-items .footer-nav {
        margin-top: 1rem;
    }
    
    footer .footer-items .social-icons {
        margin-top: 1rem;
        margin-left: auto;
    }
    
    footer .footer-items .footer-nav a {
        font: normal normal bold 10px/53px Quasimoda;
        margin: 0 8px;
    }
    
    footer .copyright h3 {
        margin-top: 40px;
        font: normal normal bold 8px/9px Quasimoda;
    }
}

@media screen and (max-width: 350px){
    footer .footer-items .footer-nav a{
        margin: 0 4px !important;
    }
}
@media screen and (min-width: 992px) and (max-width: 1080px){
    #features .item .content p{
        width: 320px;
    }
}
@media screen and (min-width: 768px) and (max-width: 991.99px){
    #features .item .content p{
        width: 250px;
    }
    #features .item .content h3{
        font-size: 14px;
    }
}


</style>